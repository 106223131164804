import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Roll from '../components/Roll'
import HrefLang from '../utils/HrefLang'
import {
  Box
} from '@chakra-ui/react'

const TagTemplate = ({ data, pageContext }) => {
  // const pageContent = data.prismic.allArchives.edges[0]
  // if (!pageContent) return null
  // const page = pageContent.node

  // const books = data.prismic.allBooks.edges
  // const posts = data.prismic.allPosts.edges

  // console.log( 'posts', posts )


  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node

  // const filterBooks = ( books ) => {
  //   let filteredBooks = []
  //   for (let index = 0; index < books.length; index++) {
  //     const book = books[index];
  //     if( book.node.is_visible || book.node.is_visible === null){
  //       filteredBooks.push( book )
  //     }
  //   }
  //   return filteredBooks
  // }

  // if (page) {
    const { posts } = data
    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={{ lang: pageContext.langIso,  }}
        lang={pageContext.langIso}
        color="light"
      >
        <Roll items={posts.edges} slugPrefix={'tag'} />
      </Layout>
    )
}

export const query = graphql`
query tagQuery( $lang: String, $tag: String) {
  posts:
    allPrismicPost( filter: { tags: { eq: $tag }}  ){
      edges{
        node{
          id
          uid
          lang
          alternate_languages{
            lang
            type
            uid
          }
          tags
          data{
            title{ text }
            seo_title{ text }
            seo_description{ text }
            intro{ text raw }
            sharing_image{ fixed{ src }}
            featured_image{ fixed{ src }}
          }
        }
      }
    }

  prismic {
    allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges{
        node{
          _meta{
            id
            uid
            type
            lang
          }
          is_visible
          title
          intro
          cover
        }
      }
    }
    allPosts (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges{
        node{
          _meta{
            id
            uid
            type
            lang
          }
          title
          intro
          featured_image
          is_visible
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export default TagTemplate
